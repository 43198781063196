import React, { useState, useEffect, useRef } from 'react'
import styles from "../styles/index.module.css"
import Select from "react-select"
import Layout from '../components/layout'
import inputstyles from "../styles/input.module.css"
import Input from "../components/input"
import { EMPTY_FIELD,  INVALID_URL } from "../helpers/errors"
import validator from "validator"
import PromptButton from "../components/promptButton"
import { Link } from "gatsby"
import  Cite  from 'citation-js'
import { navigate } from '@reach/router'

const AddToCurated = () => {
  const [form, setForm] = useState({})
  const [doi, setDoi] = useState()
  const [errors, setErrors] = useState(false)
  const [fetching, setFetching] = useState(false)
  let [digests, setDigests] = useState([]);
  let [selectedDigests,setSelectedDigests] = useState()
  const [errorMessage, setErrorMessage] = useState('');
  let submitButtonRef = useRef(null);
  let [doiDetails, setDoiDetails] = useState({});
  let [citation, setCitation] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;

    }
    fetch('/.netlify/functions/listDigests', {
        method: 'GET',
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.Digests) {
          const options = data.Digests.map( a => { return { label: a.Title, value: a.RecordId } } )
          //  setDigests(data.Digests)
          setDigests(options)
          // find selected ones, and add them
          const selected = data.Digests.filter( a => {
            if ( a.Title === "Highlights from NIH RADx initiatives" || a.Title ==="Curated News and Articles" ) {
              return true
            }
          }).map( a => { return  { label: a.Title, value: a.RecordId } } )
          setSelectedDigests(selected)
        } else {
          setDigests({})
        }
      })
  }, [] );

  async function  handleChange(e) {
    form[e.target.name] = e.target.value.trim()
    setForm({...form})
      if (e.target.name == 'doi'){

          const data = await Cite.async(e.target.value.trim())
          setCitation( data.format('bibliography', {
            format: 'html',
            template: 'apa',
            lang: 'en-US'
          } ) )
            setDoiDetails(data);
      }
  }
  function handleSelect(data) {
    setSelectedDigests(data);
  }
  const handleSubmit = () => {
    let hasErrors = false

    if (citation === ""){
      hasErrors=true
    }
    console.log(selectedDigests)
    console.log()
   //const hasErrors = !form.url?.length || !validator.isUrl(form.url ?? '')
    //setErrors(hasErrors)
    if(!hasErrors) {
      setFetching(true)
      fetch('/.netlify/functions/addToDigest', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({
          cite: doiDetails,
          digests: selectedDigests
        })
      })
        .then(response => response.json())
        .then(data => {
          if(data.success) {
            navigate('/addToCurated')
            window.close()
          } else {
            //navigate('/addToCurated?d=failed')
            console.log("subscribe failed")
            setErrors(true)
            setErrorMessage(" Failed.  Slack david valetine with DOI" + data?.error)
          }



        })
    }
  }
  return (<Layout>
    <div className={styles.form}>
      <h2 >Add Article to Curated or RadX Digests</h2>
      <div>              <label htmlFor="digests">Choose a Digest:</label>

        <Select name="digests" id="digests" options={digests}  value={selectedDigests} isMulti className={inputstyles.input}
                disabled={false} onChange={handleSelect} >


        </Select>
      </div>

      <label>DOI URL: eg. https://doi.org/10.1016/S0140-6736(22)02597-1
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <Input type={'text'} name={'doi'}  value={doi ?? ''} error={errors && !doi?.length? EMPTY_FIELD : errors && !validator.isUrl(doi ?? '')? INVALID_URL : undefined} onChange={handleChange}
               value={doi} />
      </label>

      <label>Citation
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <div dangerouslySetInnerHTML={{__html: citation}}>
        </div >

      </label>
      <PromptButton ref={ref => submitButtonRef = ref} primary={true} handleClick={handleSubmit}>Submit</PromptButton>
      <hr/>
      <div className={styles.recoverLink}>
        <Link to={`/`}>Back Home</Link>
      </div>
    </div>
    {errorMessage && (
      <p className="error"> {errorMessage} </p>
    )}
  </Layout>  )
}

export default AddToCurated
